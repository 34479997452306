import ChanelAd from "../images/ny04.jpg";
import Ads from "../Ads/Ads.component";
import Card from "./Card.component";
import { useState } from "react";
import GongqunGroup from "../Gongqun/GongqunGroup.component";
import { Link } from "react-router-dom";

const Contact = () => {
  const [main, setMain] = useState("");
  return (
    <div className="contact-main">
      <div
        style={{ backgroundImage: "url(/images/bg_channel.png)" }}
        className="flex  justify-center"
      >
        <Ads src={ChanelAd} width="1047" height="127" />
      </div>
      <div className="w-[1200px] mx-auto">
        {/* <div className="card-list flex gap-4 my-[16px]">
          <Card
            head="综合业务客服"
            number={`cpdd`}
            info={`咨询、拉群、广告、会员、解封、投诉、验群、丟失群恢复、纠纷仲裁、资源对接`}
          />
          <Card
            head="综合业务客服"
            number={`cpdd`}
            info={`咨询、拉群、广告、会员、解封、投诉、验群、丟失群恢复、纠纷仲裁、资源对接`}
          />
          <Card
            head="综合业务客服"
            number={`cpdd`}
            info={`咨询、拉群、广告、会员、解封、投诉、验群、丟失群恢复、纠纷仲裁、资源对接`}
          />
        </div> */}
        <div className="contact-main bg-[#f5f5f5] rounded-[5px] mb-[25px] py-[20px] px-[30px] leading-[35px] mt-[16px]">
          <GongqunGroup
            name={`币安官方频道:`}
            buttons={[
              {
                name: "官方频道  公告通知",
                link: "https://t.me/bian",
              },
              {
                link: "https://t.me/BADB",
                name: "币安担保  每日公群",
              },
              {
                link: "https://t.me/BAGQ",
                name: "币安公群  静态导航",
              },
              {
                link: "https://t.me/BAGX",
                name: "币安供需  付费广告",
              },
              {
                link: "https://t.me/UBER",
                name: "纠纷仲裁  防骗曝光",
              },
            ]}
          />

          <GongqunGroup
            name={`币安官方工作人员:`}
            buttons={[
              {
                name: "公群负责人",
                link: "https://t.me/CPDD",
              },
              {
                link: "https://t.me/USDD",
                name: "商务负责人",
              },
              {
                link: "https://t.me/ZARA",
                name: "供需负责人",
              },
              {
                link: "https://t.me/BILL",
                name: "财务负责人",
              },
              {
                link: "https://t.me/YUYU",
                name: "专群客服一",
              },
              {
                link: "https://t.me/GBPT",
                name: "专群客服二",
              },
              {
                link: "https://t.me/ZOOM",
                name: "纠纷仲裁一",
              },
              {
                link: "https://t.me/SCZL",
                name: "纠纷仲裁二",
              },
              {
                link: "https://t.me/ORDI",
                name: "纠纷接待一",
              },
              {
                link: "https://t.me/SEDO",
                name: "纠纷接待二",
              },
            ]}
          />
          <GongqunGroup
            name={`投诉/意见/建议/问题反馈:`}
            buttons={[
              {
                name: "币安监督",
                link: "https://t.me/JianDu",
              },
              {
                link: "https://t.me/JianCha",
                name: "币安监察",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
export default Contact;
