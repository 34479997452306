import { Link } from "react-router-dom";
import PIC01 from "../images/pic_01.png";
import PIC02 from "../images/pic_02.png";
import PIC03 from "../images/pic_03.png";
import PIC04 from "../images/pic_04.png";
import PIC05 from "../images/pic_05.png";
const HomeShow = () => {
  return (
    <div className=" mt-[30px]  bg-[#f5f5f5] w-[1200px] mx-auto p-[15px] rounded-[12px] pt-[35px] pb-[43px]">
      <div className="flex flex-col	">
        <div className="text-[#2c2c2c] self-center text-[24px]">
          Over $6 billion of transactions protected with BiAn
        </div>
        <div className="self-center text-[#8c8b8b] text-[14px] mt-[5px]">
          币安担保是世界上最安全的支付系统，保障了交易双方的安全
        </div>
        <div className="flex flex-row w-[1120px] mx-auto justify-center gap-[50px]">
          <div className="flex flex-col w-[150px]">
            <img src={PIC01} className="h-[150px] mt-[20px]" />
            <div className="self-center w-[170px] text-[16px] text-center text-[#2c2c2c] mt-[28px]">
              1.买方和卖方就交易条款达成一致
            </div>
          </div>
          <div className="flex flex-col w-[150px]">
            <img src={PIC02} className="h-[150px] mt-[20px]" />
            <div className=" self-center w-[170px] text-[16px] text-center text-[#2c2c2c] mt-[28px]">
              2.买方将支付款项打给币安担保
            </div>
          </div>
          <div className="flex flex-col w-[150px]">
            <img src={PIC03} className="h-[150px] mt-[20px]" />
            <div className=" self-center w-[170px] text-[16px] text-center text-[#2c2c2c]  mt-[28px]">
              3.卖方给买方发送交易物品或提供相应服务
            </div>
          </div>
          <div className="flex flex-col w-[150px]">
            <img src={PIC04} className="h-[150px] mt-[20px]" />
            <div className=" self-center w-[170px] text-[16px] text-center text-[#2c2c2c] mt-[28px]">
              4.买方确认好物品或服务
            </div>
          </div>
          <div className="flex flex-col w-[150px]">
            <img src={PIC05} className="h-[150px] mt-[20px]" />
            <div className=" self-center w-[170px] text-[16px] text-center text-[#2c2c2c] mt-[28px]">
              5.币安担保将交易款 项转移给卖方
            </div>
          </div>
        </div>
        <div className="self-center  mt-[20px]">
          <Link to={`/contact`}>
            <button className="bg-[#ce7205] w-[200px] h-[50px] text-[#fff] rounded-lg">
              联系我们
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default HomeShow;
