import { Link } from "react-router-dom";
import logo from "../images/logo.png";
const Logo = () => {
  return (
    <Link to="/">
      <div className=" w-49 flex-none">
        <img src={logo} alt="" className="h-full" />
      </div>
    </Link>
  );
};

export default Logo;
