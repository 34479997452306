import Ads from "../Ads/Ads.component";
import ChanelAd from "../images/ny01.jpg";
import GongqunGroup from "./GongqunGroup.component";
import List from "../ContentList/List.component";
import Item from "../ContentList/Item.component";
import ListHeader from "../ContentList/Header.component";
import { Link } from "react-router-dom";
import Lb from "../images/lb.png";
import { useState, useEffect, useRef } from "react";
import Xft from "../images/xfj.png";
// const GetList = () => {
//   useEffect(() => {
//     async function fetchData() {
//       const res = await fetch("https://htadmin.biandanbao.com/api/random?from=2");
//       const data = await res.json();
//       // console.log(data);
//       return data.data.map((item) => {
//         return {
//           id: item.id,
//           title: item.title,
//           content: item.info.slice(0, 70) + "...",
//           time: item.publish_time_timestamp,
//           type: item.type,
//           from: item.from,
//         };
//       });
//       // setNeedList(data);
//     }
//     return fetchData();
//   }, []);
// };
const Gongqun = () => {
  const [needList2, setNeedList2] = useState([]);
  const [change, setChange] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        "https://htadmin.biandanbao.com/api/random?from=2"
      );
      const data = await res.json();
      // console.log(data);
      setNeedList2(
        data.data.map((item) => {
          return {
            id: item.id,
            title: item.title,
            content: item.info.slice(0, 70) + "...",
            time: item.publish_time_timestamp,
            type: item.type,
            from: item.from,
          };
        })
      );
      // setNeedList(data);
    }
    fetchData();
  }, [change]);
  return (
    <div className="gongqun">
      <div
        style={{ backgroundImage: "url(/images/bg_channel.png)" }}
        className="flex  justify-center"
      >
        <Ads src={ChanelAd} width="1047" height="127" />
      </div>
      <div className="w-[1200px] mx-auto my-[30px]">
        <div className="bg-[#f1f1f1] p-[20px] rounded-[10px]">
          <p className="my-[20px]">
            温馨提示：群内所有服务由商户提供，请联系带“群老板”、“业务员”字样的商户方管理员进行交易，汇旺担保只提供担保服务
          </p>
          <p className="my-[20px]">
            公群分类快速直达：（ 点击对应分类可以跳转至telegram频道中对应位置 ）
          </p>
          <div className="my-[20px]">
            <p className="border-l-4 border-red-800 pl-[20px] mb-[20px]">
              跑分类公群
            </p>
            <p className="flex flex-row gap-[20px]">
              <a href="https://t.me/bagq/26">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  码接回u
                </button>
              </a>
              <a href="https://t.me/bagq/27">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  卡接回u
                </button>
              </a>
              <a href="https://t.me/bagq/41">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  代收代付
                </button>
              </a>
              <a href="https://t.me/bagq/42">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  招车队窗口群（群内不做业）
                </button>
              </a>
            </p>
          </div>
          <div className="my-[20px]">
            <p className="border-l-4 border-red-800 pl-[20px] mb-[20px]">
              博彩类公群
            </p>
            <p className="flex flex-row gap-[20px]">
              <a href="https://t.me/bagq/78">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  快三 视讯 红包 骰子 加拿大 PC28 足球
                </button>
              </a>
            </p>
          </div>
          <div className="my-[20px]">
            <p className="border-l-4 border-red-800 pl-[20px] mb-[20px]">
              做单类公群
            </p>
            <p className="flex flex-row flex-wrap gap-[20px]">
              <a href="https://t.me/bagq/79">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  租苹果ID①区
                </button>
              </a>
              <a href="https://t.me/bagq/80">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  租苹果ID②区
                </button>
              </a>
              <a href="https://t.me/bagq/81">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  租苹果ID③区
                </button>
              </a>
              <a href="https://t.me/bagq/82">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  ZM/PC
                </button>
              </a>
              <a href="https://t.me/bagq/83">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  视频代发①区
                </button>
              </a>
              <a href="https://t.me/bagq/84">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  视频代发②区
                </button>
              </a>
              <a href="https://t.me/bagq/85">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  拉手/Q/微拉推
                </button>
              </a>
              <a href="https://t.me/bagq/86">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  引流/出粉/数据
                </button>
              </a>
              <a href="https://t.me/bagq/87">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  代聊话术单
                </button>
              </a>
              <a href="https://t.me/bagq/88">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  IM短信/彩信
                </button>
              </a>
              <a href="https://t.me/bagq/89">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  美团下单
                </button>
              </a>
              <a href="https://t.me/bagq/90">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  电销
                </button>
              </a>
              <a href="https://t.me/bagq/91">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  闲鱼/淘宝
                </button>
              </a>
              <a href="https://t.me/bagq/92">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  苹果ID代充类
                </button>
              </a>
              <a href="https://t.me/bagq/93">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  地推
                </button>
              </a>
              <a href="https://t.me/bagq/94">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  收/出抖币快币
                </button>
              </a>
              <a href="https://t.me/bagq/95">
                <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
                  取信件
                </button>
              </a>
            </p>
          </div>
          <GongqunGroup
            name="手机口公群"
            buttons={[
              {
                name: "手机口①区",
                link: "https://t.me/bagq/96",
              },
              {
                name: "手机口②区",
                link: "https://t.me/bagq/97",
              },
              {
                name: "手机口③区",
                link: "https://t.me/bagq/98",
              },
              {
                name: " 手机口④区",
                link: "https://t.me/bagq/99",
              },
              {
                name: "手机口⑤区",
                link: "https://t.me/bagq/100",
              },
              {
                name: "手机口⑥区",
                link: "https://t.me/bagq/101",
              },
              {
                name: "手机口⑦区",
                link: "https://t.me/bagq/102",
              },
              {
                name: "手机口⑧区",
                link: "https://t.me/bagq/103",
              },
              {
                name: "手机口⑨区",
                link: "https://t.me/bagq/104",
              },
              {
                name: "手机口⑩区",
                link: "https://t.me/bagq/105",
              },
              {
                name: " 有线手机口",
                link: "https://t.me/bagq/106",
              },
              {
                name: "呼叫转移",
                link: "https://t.me/bagq/107",
              },
            ]}
          />
          <GongqunGroup
            name="承兑 TRX类公群"
            buttons={[
              {
                name: "USDT承兑",
                link: "https://t.me/bagq/108",
              },
              {
                name: "TRX闪兑/能量租赁",
                link: "https://t.me/bagq/110",
              },
            ]}
          />
          <GongqunGroup
            name="电报各类业务/辅助工具类公群"
            buttons={[
              {
                name: "话费代充",
                link: "https://t.me/bagq/112",
              },
              {
                name: "TG强拉/会员代开",
                link: "https://t.me/bagq/113",
              },
              {
                name: "888号码",
                link: "https://t.me/bagq/114",
              },
              {
                name: "接码",
                link: "https://t.me/bagq/115",
              },
              {
                name: "网站搭建/渗透/攻击类",
                link: "https://t.me/bagq/118",
              },
              {
                name: " 天眼查档",
                link: "https://t.me/bagq/119",
              },
              {
                name: "收售/租赁账号",
                link: "https://t.me/bagq/120",
              },
            ]}
          />
          <GongqunGroup
            name="实物交易：烟草/手机卡/证件办理类公群"
            buttons={[
              {
                name: "烟草出售",
                link: "https://t.me/bagq/121",
              },
              {
                name: "手机卡",
                link: "https://t.me/bagq/122",
              },
              {
                name: "证件办理",
                link: "https://t.me/bagq/123",
              },
              {
                name: "亚马孙店铺注册",
                link: "https://t.me/bagq/124",
              },
            ]}
          />
          <GongqunGroup
            name="其他小众公群综合类目"
            buttons={[
              {
                name: "苹果ID贷款",
                link: "https://t.me/bagq/125",
              },
              {
                name: "外围裸聊",
                link: "https://t.me/bagq/126",
              },
              {
                name: "美工设计",
                link: "https://t.me/bagq/127",
              },
            ]}
          />
          <GongqunGroup
            name="币安机器人"
            buttons={[
              {
                name: "币安机器人",
                link: "https://t.me/badbbot",
              },
            ]}
          />
        </div>
        <div className="gongqun_recommend my-[20px]">
          <List>
            <ListHeader>
              <div className="flex flex-row justify-between mb-[30px] relative	pl-[40px] pr-[30px]">
                <img
                  src={Lb}
                  className="absolute left-[10px] top-[12px] w-[26px] h-[26px] "
                />
                <div className=" pl-1 text-[#b36a12] text-[30px]">
                  最新公群
                  <span className="text-[#33abe0] relative">
                    <img
                      src={Xft}
                      alt=""
                      className="w-[32px] h-[32px] inline absolute top-[5px] left-[20px]"
                    />
                    <span className="ml-[65px]">
                      {/* <Link to="https://t.me/BIAN">@BIAN</Link>
                  {` `} */}
                      <Link to="https://t.me/BADB" className="text-[30px]">
                        @BADB
                      </Link>
                    </span>
                  </span>
                </div>
                <span
                  className="text-[#565656] text-[14px] leading-[28px] self-center cursor-pointer"
                  onClick={() => setChange(!change)}
                >
                  换一批
                </span>
              </div>
            </ListHeader>
            <div className="flex flex-row flex-wrap justify-start gap-[18px]">
              {needList2.map((item, index) => (
                <Item key={index} item={item} />
              ))}
            </div>
          </List>
        </div>
      </div>
    </div>
  );
};

export default Gongqun;
