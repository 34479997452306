import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Item from "../ContentList/Item.component";
import List from "../ContentList/List.component";
import Page from "../Components/Page.component";
import Search from "../Components/Search.component";
import ChanelAd from "../images/ny02.jpg";
import ListHeader from "../ContentList/Header.component";
import Lb from "../images/lb.png";
import Ads from "../Ads/Ads.component";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SearchList = () => {
  const [allList, setAllList] = useState([]);
  const [count, setCount] = useState(0); // [总数]
  const query = useQuery();
  const keywords = query.get("query") || "";
  const currentPage = parseInt(query.get("page")) || 1;
  const [limit, setLimit] = useState(10); // [每页显示的数量
  const from = query.get("from") || "";
  useEffect(() => {
    const controller = new AbortController();

    async function fetchData() {
      try {
        const res = await fetch(
          `https://htadmin.biandanbao.com/api/searchInfos?current_page=
          ${currentPage} 
          &keywords=${keywords ? keywords : ""}&from=${from ? from : ""}`,
          { signal: controller.signal }
        );
        const data = await res.json();
        // console.log(data);
        setAllList(
          data.list.map((item) => {
            return {
              id: item.id,
              title: item.title,
              content: item.info.slice(0, 60) + "...",
              time: item.publish_time_timestamp,
              type: item.type,
              from: item.from,
            };
          })
        );
        setCount(data.count); // [3]
        setLimit(data.limit); // [4]
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
    return () => controller.abort();
  }, [currentPage, keywords, from]);
  return (
    <div className="main ">
      <div
        style={{ backgroundImage: "url(/images/bg_channel.png)" }}
        className="flex  justify-center"
      >
        <Ads src={ChanelAd} width="1047" height="127" />
      </div>
      <div className="h-[48px] my-[30px]">
        <Search inputWidth="900px" from={from} />
      </div>

      <div className="min-h-[902px]">
        <List>
          <ListHeader>
            <div className="flex flex-row justify-between mb-[30px] relative	pl-[40px] pr-[30px]">
              <img
                src={Lb}
                className="absolute left-[10px] top-[12px] w-[26px] h-[26px] "
              />
              <div className=" pl-1 text-[#b36a12] text-[30px]">
                {from === 1 ? `需求列表` : "币安供需 付费广告"}
              </div>
            </div>
          </ListHeader>
          <div className="flex flex-row flex-wrap justify-start gap-[10px]">
            {allList.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </div>
        </List>
      </div>

      <Page
        Search={keywords}
        limit={limit}
        current={currentPage}
        count={count}
        from={from}
      />
    </div>
  );
};

export default SearchList;
