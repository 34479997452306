import Ads from "../Ads/Ads.component";
import ChanelAd from "../images/p_channel01.jpg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Left from "./Left.component";
import Right from "../Components/Right.component";
import { useEffect, useRef, useState } from "react";

const Detail = () => {
  const [detail, setDetail] = useState({}); // [1]
  const [idParams] = useSearchParams();
  const id = idParams.get("id");
  const firstUpdate = useRef(true);
  useEffect(() => {
    // if (firstUpdate.current) {
    //   console.log("firstUpdate");
    //   firstUpdate.current = false;
    //   return;
    // }
    async function fetchData() {
      const res = await fetch(
        `https://htadmin.biandanbao.com/api/info?id=${id}`
      );
      const data = await res.json();
      console.log(data);
      setDetail({
        title: data.title,
        time: data.publish_time_timestamp,
        browse: data.views,
        contact: "",
        content: data.info,
        type: data.type,
      });
    }
    fetchData();
  }, [id]);
  return (
    <>
      <div
        style={{ backgroundImage: "url(/images/bg_channel.png)" }}
        className="flex  justify-center"
      >
        <Ads src={ChanelAd} width="1047" height="127" />
      </div>
      <div className="flex w-[1200px] my-[20px] ml-[150px]">
        <span>
          <span>当前位置：</span>
          <Link to="/">首页 &gt;</Link>
          <Link to="/search-list">供求信息 &gt;</Link>
          <Link to="/detail">详情</Link>
        </span>
      </div>
      <div className=" flex justify-center w-[1200px] mx-auto gap-[50px] ">
        <Left detail={detail}></Left>
        <Right></Right>
      </div>
    </>
  );
};
export default Detail;
