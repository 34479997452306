const GongQunButton = ({ link, name }) => {
  return (
    <a href={link}>
      <button className=" h-[50px] bg-transparen text-[#000] border border-[#000] px-[20px] py-[5px] rounded-[5px]">
        {name}
      </button>
    </a>
  );
};

export default GongQunButton;
