import GongQunButton from "./GongQunButton.component";

const GongqunGroup = ({ name, buttons }) => {
  return (
    <div className="my-[20px]">
      <p className="border-l-4 border-red-800 pl-[20px] mb-[20px]">{name}</p>
      <p className="flex flex-row gap-[20px] flex-wrap">
        {buttons.map((button) => (
          <GongQunButton link={button.link} name={button.name} />
        ))}
      </p>
    </div>
  );
};

export default GongqunGroup;
