import { Fragment, useEffect, useState } from "react";
import Button from "../Components/Button.component";
import Logo from "../Components/Logo.component";
import { Outlet, Link } from "react-router-dom";
import Footer from "../Footer/Footer.component";
const Header = () => {
  const [active, setActive] = useState(1);
  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      setActive(1);
    } else if (path === "/gongqun") {
      setActive(2);
    } else if (path === "/search-list") {
      setActive(3);
    } else if (path === "/gongqun-center") {
      setActive(4);
    } else if (path === "/contact") {
      setActive(5);
    } else if (path === "/about") {
      setActive(6);
    }
  }, []);
  return (
    <>
      <div className="bg-header-white w-full mb-[11px]">
        <div className="flex flex-row h-[100px]  w-[1200px] mx-auto min-w-[800px] justify-between">
          <Logo></Logo>
          <div className="flex justify-between basis-1/2 leading-normal items-center">
            <Link
              to="/"
              className={`hover:text-[#ce7205] ${
                active === 1 ? "text-[#ce7205]" : ""
              }`}
              onClick={() => setActive(1)}
            >
              首页
            </Link>
            <Link
              to={`gongqun`}
              className={`hover:text-[#ce7205] ${
                active === 2 ? "text-[#ce7205]" : ""
              }`}
              onClick={() => setActive(2)}
            >
              担保公群
            </Link>
            <Link
              to={`search-list?query=&page=1&from=1`}
              className={`hover:text-[#ce7205] ${
                active === 3 ? "text-[#ce7205]" : ""
              }`}
              onClick={() => setActive(3)}
            >
              供需广告
            </Link>

            <Link
              to={`gongqun-center?query=&page=1&newsKey=1`}
              className={`hover:text-[#ce7205] ${
                active === 4 ? "text-[#ce7205]" : ""
              }`}
              onClick={() => setActive(4)}
            >
              公告中心
            </Link>
            <Link
              to={`contact`}
              className={`hover:text-[#ce7205] ${
                active === 5 ? "text-[#ce7205]" : ""
              }`}
              onClick={() => setActive(5)}
            >
              联系我们
            </Link>
            <Link
              to={`about`}
              className={`hover:text-[#ce7205] ${
                active === 6 ? "text-[#ce7205]" : ""
              }`}
              onClick={() => setActive(6)}
            >
              关于我们
            </Link>
          </div>
        </div>
      </div>

      <Outlet />
      <Footer></Footer>
    </>
  );
};
export default Header;
