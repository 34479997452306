import { Link } from "react-router-dom";

const Ads = ({ src, width = "full", height = 378, style = {} }) => {
  return (
    <Link to="https://t.me/BAGQ">
      <img
        className={`w-${
          width === "full" ? "full" : `${width}px`
        } h-[${height}px]`}
        src={src}
        style={style}
      />
    </Link>
  );
};

export default Ads;
