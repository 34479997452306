import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Item from "../ContentList/Item.component";
import List from "../ContentList/List.component";
import Page from "../Components/Page.component";
import Search from "../Components/Search.component";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const All = () => {
  const [allList, setAllList] = useState([]);
  const [count, setCount] = useState(0); // [总数]
  const { state } = useLocation();
  const [limit, setLimit] = useState(10); // [每页显示的数量
  // const [keywords, setKeywords] = useState(""); // [关键字
  const query = useQuery();
  const currentPage = parseInt(query.get("page")) || 1;

  const params = useRef("");
  // const { all } = state;
  if (state !== null) {
    console.log(state);
    params.searchwords = state.searchwords;
  }
  // console.log(params.searchwords);
  useEffect(() => {
    const controller = new AbortController();

    async function fetchData() {
      try {
        const res = await fetch(
          `https://htadmin.biandanbao.com/api/searchInfos?current_page=
          ${currentPage} 
        `,
          { signal: controller.signal }
        );
        const data = await res.json();
        // console.log(data);
        setAllList(
          data.list.map((item) => {
            return {
              id: item.id,
              title: item.title,
              content: item.info.slice(0, 60) + "...",
              time: item.publish_time_timestamp,
              type: item.type,
            };
          })
        );
        setCount(data.count); // [3]
        setLimit(data.limit); // [4]
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
    return () => controller.abort();
  }, [currentPage]);
  return (
    <div className="main ">
      <Search></Search>
      <div className="min-h-[902px]">
        <List>
          <div className="flex flex-row flex-wrap justify-start gap-[10px]">
            {allList.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </div>
        </List>
      </div>
      <Page Search="" limit={limit} current={currentPage} count={count} />
    </div>
  );
};

export default All;
