const Modal = ({ header, info, onclickClose }) => {
  return (
    <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-white px-4 border rounded border-[#ccc] border-solid	shadow-[0_0_8px_rgba(0, 0, 0, 0.2)]">
      <div className="flex flex-row	justify-between	items-center	pb-2 border-b border-[#ccc] border-solid	">
        <h2 className="my-[10px]">{header}:</h2>
      </div>
      <div className="p-[16px] max-h-[calc(100vh-190px)] ">
        <p>{info}</p>
      </div>
      <div className="flex flex-row justify-end pt-[8px] border-t border-[#ccc] border-solid "></div>
      <button
        className="bg-transparent	 cursor-pointer border-0"
        onClick={onclickClose}
      >
        关闭
      </button>
    </div>
  );
};

export default Modal;
