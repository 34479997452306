const ItemHeader = ({ type }) => {
  return (
    <div className=" h-[34px] mt-[20px] content-center flex flex-row justify-center leading-[34px]">
      <div
        className={` text-center w-[50px] h-full rounded-l-full ${
          type === 1 ? "bg-[#c00808] text-white" : "bg-[#d3d3d3]"
        } `}
      >
        供
      </div>
      <div
        className={` text-center w-[50px] h-full rounded-r-full  ${
          type === 2 ? "bg-[#ce7205] text-white" : "bg-[#d3d3d3]"
        }`}
      >
        {type === 2 ? `求` : "需"}
      </div>
    </div>
  );
};

export default ItemHeader;
