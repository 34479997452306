import Ads from "../Ads/Ads.component";
import ChanelAd from "../images/222.jpg";
const About = () => {
  return (
    <div className="about">
      <div
        style={{ backgroundImage: "url(/images/bg_channel.png)" }}
        className="flex  justify-center"
      >
        <Ads src={ChanelAd} width="1047" height="127" />
      </div>
      <div className="w-[1200px] mx-auto my-[30px]">
        <div className="about-content bg-[#f5f5f5] round-[5px] py-[50px] px-[40px] flex flex-col">
          <div className="mx-auto text-center text-5xl	">欢迎来到币安担保</div>
          <h2 className="font-extrabold text-lg">什么是BiAn担保？</h2>
          <p className="indent-8">
            BiAn又名币安担保，大家习惯称之为币安担保，因为一想到货币安全，一想到担保，大家就心里就只有币安担保！
          </p>
          <p className="indent-8">
            币安担保是一家总部位于科罗拉多州的在线托管担保服务公司。BiAn在科罗拉多州注册和密苏里州，拥有托管担保服务许可。并获得了美国MSB金融牌照和美国NFA非会员牌照。
          </p>
          <p className="indent-8">
            美国MSB（Money Services
            Business）牌照是由FinCEN（美国财政部下设机构金融犯罪执法局）监管并颁发的金融牌照，主要收监管对象是金钱服务相关的业务与公司。
            范围包括国际汇款、外汇兑换、货币交易/转移（包括数字货币/虚拟货币）、ICO
            发行、提供预付项目、签发旅行支票等业务。美国NFA非会员牌照，由美国金融管理协会NFA颁发，
            NFA是迄今为止唯一获得美国商品期货交易委员会(CFIC)批准的期货协会，持牌人可以商品运营商（CPO）、商品交易顾问（CTA）权限，可募集资金，提供期货合约、外汇合约交易等业务。
            币安担保于2022年开始筹备，并在2023年中正式成立开展业务，凭借精明的市场战略，丰厚财力和资源，先后斥资1500万，打造了多个红极一时的人气流量社群，并持续出手，收购并整合了包括了SCZL，JIEJIE在内的多家担保公司和机构，巩固了币安担保在行业中的领导地位。特别值得一提的是，在2023年底，币安担保通过并购和信誉理赔方式成功整合了行业第三大公司HZZ担保，一举成为全网第一流量担保社群，成为担保行业的主导力量，标志着币安担保在全球华人市场取到巨大成功和卓越地位。
          </p>
          <p className="indent-8">
            2024年，币安担保已安全地处理了价值超过数亿美元的交易。保持稳步上升并继续在国际上发展，如今已成功在美国科罗拉多州和密苏里州开设办事处，获得了美国MSB金融牌照，美国NFA非会员牌照。并将于2024年11月20日，在英国伦敦举办周年庆典盛筵！
          </p>
          <h2 className="font-extrabold text-lg">担保是什么意思？</h2>
          <p className="indent-8">
            担保是一种第三方托管服务，旨在保护交易中的买方和卖方。独立第三方收取一定费用，保管付款，直到交易中每个人都履行各自的责任。
          </p>
          <p className="indent-8">
            具体来说：交易双方可共同指定信誉良好的担保机构，作为独立第三方开立托管担保账户代为保管及释放交易资金。只有在买卖双方确认产品符合要求之后，托管代理才会释放资金，买方便可以更放心地把资金汇入到此账户中。通过担保托管付款，卖方只有在买方收到并接受交易的产品和/或服务后才会收到资金。但是，卖方知道他们会收到付款，因为币安担保
            会代他们保管资金。
          </p>
          <h2 className="font-extrabold text-lg">
            您可以通过 BiAn币安担保 购买或出售什么？
          </h2>
          <p className="indent-8">
            选择 @BiAn 担保交易，您可以轻松安全地从世界任何地方购买任何商品。
            无论您是购买或出售：
          </p>
          <ul className="list-disc list-inside">
            <li>网站、域名、网络技术开发、UIUX设计服务，高端人才交易服务</li>
            <li>机动车、游艇、飞机及其他机动引擎设备</li>
            <li>
              手表、电脑、相机、音响等电子设备 二手商品、地摊杂物或日用百货
            </li>
            <li>珠宝、奢侈品、古董、字画、房地产</li>
            <li>知识产权、数字货币、NFT或其它虚拟资产</li>
          </ul>
          <p className="indent-8">
            无论您在哪里在线销售或购买商品和服务，无论是拍卖网站、在线市场还是
            B2B 电子商务网站，托管服务都会确保您的安全。
          </p>
          <p className="indent-8">
            在网上做买卖，一定要选择@BiAn，币安担保提供真正安全的付款方式，为卖方免除拒付风险，为买方提供第三方收货担保！
          </p>
          <p className="indent-8">
            @BiAn
            是全球最安全的担保平台，让您能在全球范围内安心在线买卖商品，保障交易双方的安全。
          </p>
          <h2 className="font-extrabold text-lg">何时使用担保服务？</h2>
          <p className="indent-8">
            严格来说，不管是和熟人或陌生人交易，无论是线上线下交易，都可以使用担保服务进行在线高价值交易。我们平等保护买家、卖家和经纪人，从而消除与未知方进行交易的任何风险。避免欺诈对于买卖双方至关重要！幸运的是，@BiAn
            担保的简单流程确保每次交易的资金转移和商品交付都安全无虞。@BiAn
            担保确保每一方都能收到约定的款项。
          </p>
          <p className="indent-8">
            “在@BiAn，安全、可靠且保密地在线购买或出售任何东西。选币安，心必安！”
          </p>
          <h2 className="font-extrabold text-lg">币安担保如何运作？</h2>
          <p className="indent-8">
            我们保证买方得到所购商品，同时保证卖方获得应有的报酬。无论您是在线购买还是销售任务物资，@BiAn担保保护您免受欺诈、欺骗和不负责任的行为。作为一个中立、信誉良好且负责任的第三方服务提供商，@BiAn将买方的付款安全地存放在信托账户中，直到交易结束。
          </p>
          <p className="indent-8">
            币安担保充当了值得信赖的第三方，在买方和卖方都满意的情况下收集、持有并仅支付资金，从而降低了欺诈风险。
          </p>
          <ol className="list-decimal list-inside">
            <li>
              买方和卖方同意条款。买方或卖方发起交易。在币安开设公群或专群后，所有各方均同意交易条款。
            </li>
            <li>
              买方向币安担保付款。币安担保验证付款，然后通知卖方资金已“托管担保”。
            </li>
            <li>
              卖方将商品运送给买方。付款验证后，卖方有权发送商品并提交跟踪信息。币安担保验证买方是否收到商品。
            </li>
            <li>
              买家接受商品。买家按照同意条款检查商品，并可以选择接受或拒绝。如果不被批准，买家将提出争议并进入争议纠纷解决或仲裁程序。
            </li>
            <li>
              币安担保向卖家付款。如果商品被接受，币安担保将从托管账户中向卖家发放资金。
            </li>
          </ol>
          <h2 className="font-extrabold text-lg">币安担保-免责声明</h2>
          <p className="indent-8">
            BiAn担保平台不参与也不了解客户的具体业务操作。作为担保方，我们的职责仅限于确保交易的一方收到货物，另一方收到款项。对于交易双方的资金或货物的来源及其用途，BiAn无法进行查证，也无法提供任何保证。请交易双方在交易前自行确认并沟通好所有相关细节，包括但不限于资金和货物的合法性及来源。如果资金或货物的来源有问题，或者使用所购买的货物从事任何违法行为，本公司概不承担任何连带责任。
          </p>
          <p className="indent-8">
            特别提醒：客户应自行遵守所在国家和地区的法律法规。如因客户行为违反相关法律法规，所引发的一切责任和后果均由客户自行承担，BiAn不承担任何责任。
          </p>
        </div>
      </div>
    </div>
  );
};
export default About;
