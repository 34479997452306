import logo from "./logo.svg";
import { Routes, Route } from "react-router-dom";
import "./output.css";
import Header from "./Header/Header.component";
import Home from "./Home/home.component";
import Detail from "./Detail/Detail.component";
import All from "./All/All.component";
import SearchList from "./All/SearchList.component";
import Contact from "./Contact/Contact.component";
import About from "./About/About.component";
import Gongqun from "./Gongqun/Gongqun.component";
import NewsDetail from "./NewsDetail/Detail.component";
import GongQunCenter from "./GongGaoCenter/GongQunCenter.component";
import InfoDetail from "./info/Detail.component";
function App() {
  return (
    <div className="App mx-auto p-0 bg-main-white h-full">
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="detail" element={<Detail />} />
          <Route path="all" element={<All />} />
          <Route path="search-list" element={<SearchList />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="gongqun" element={<Gongqun />} />
          <Route path="news-detail" element={<NewsDetail />} />
          <Route path="gongqun-center" element={<GongQunCenter />} />
          <Route path="config" element={<InfoDetail />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
