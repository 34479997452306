import { useEffect, useState } from "react";
import Ads from "../Ads/Ads.component";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ChanelAd from "../images/ny03.jpg";
import GongGaoPage from "./GongGaoPage.component";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const GongQunCenter = () => {
  const [limit, setLimit] = useState(10); // [每页显示的数量
  const [count, setCount] = useState(0); // [总数]
  const [search, setSearch] = useState(""); // [搜索关键字]
  const [newsList, setNewsList] = useState([]);
  const [newsindex, setNewsIndex] = useState(1);
  const query = useQuery();
  const keywords = query.get("query") || "";
  const currentPage = parseInt(query.get("page")) || 1;
  const newsKey = parseInt(query.get("newsKey")) || 1;
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `https://htadmin.biandanbao.com/api/searchNews?type=${newsKey}&limit=10&current_page=${currentPage}&keywords=${keywords}`
      );
      const data = await res.json();
      setLimit(data.limit);
      setCount(data.count);
      // console.log(data);
      setNewsList(
        data.list.map((item) => {
          return {
            id: item.id,
            title: item.title,
            content: item.info.slice(0, 70) + "...",
            time: item.publish_time_timestamp,
            type: item.type,
            time_string: item.publish_time,
          };
        })
      );
      // setNeedList(data);
    }
    fetchData();
  }, [newsKey, keywords, currentPage]);
  return (
    <div className="GongQunCenter">
      <div
        style={{ backgroundImage: "url(/images/bg_channel.png)" }}
        className="flex  justify-center"
      >
        <Ads src={ChanelAd} width="1047" height="127" />
      </div>
      <div className="search"></div>
      <div className="main w-[1200px] mx-auto my-[20px] ">
        <div className="relative news-header  text-[16px] bg-[#f5f5f5] leading-normal pt-[8px] rounded-full pl-[15px] h-[40px] flex justify-between ">
          <div className={`left flex flex-row gap-[10px]  `}>
            <Link
              to={`/gongqun-center?query=${search}&page=1&newsKey=1`}
              onClick={() => setNewsIndex(1)}
            >
              <div key={1} className="cursor-pointer">
                <span
                  className={`hover:text-[#ce7205] ${
                    newsindex === 1 ? "text-[#ce7205]" : ""
                  }`}
                >
                  币安新闻{" "}
                </span>
                <span className={`mx-[5px]`}>|</span>
              </div>
            </Link>
            <Link
              to={`/gongqun-center?query=${search}&page=1&newsKey=2`}
              onClick={() => setNewsIndex(2)}
            >
              <div key={2} className="cursor-pointer">
                <span
                  className={`hover:text-[#ce7205] ${
                    newsindex === 2 ? "text-[#ce7205]" : ""
                  }`}
                >
                  公告信息
                </span>{" "}
                <span className="mx-[5px]">|</span>
              </div>
            </Link>
            <Link
              to={`/gongqun-center?query=${search}&page=1&newsKey=3`}
              onClick={() => setNewsIndex(3)}
            >
              <div key={3} className="cursor-pointer">
                <span
                  className={`hover:text-[#ce7205] ${
                    newsindex === 3 ? "text-[#ce7205]" : ""
                  }`}
                >
                  常见问题
                </span>{" "}
                <span className="mx-[5px]">|</span>
              </div>
            </Link>
            <Link
              to={`/gongqun-center?query=${search}&page=1&newsKey=4`}
              onClick={() => setNewsIndex(4)}
            >
              <div key={4} className="cursor-pointer">
                <span
                  className={`hover:text-[#ce7205] ${
                    newsindex === 4 ? "text-[#ce7205]" : ""
                  }`}
                >
                  骗子曝光
                </span>{" "}
                <span className="mx-[5px]">|</span>
              </div>
            </Link>
            <Link
              to={`/gongqun-center?query=${search}&page=1&newsKey=5`}
              onClick={() => setNewsIndex(5)}
            >
              <div key={5} className="cursor-pointer">
                <span
                  className={`hover:text-[#ce7205] ${
                    newsindex === 5 ? "text-[#ce7205]" : ""
                  }`}
                >
                  防骗教学
                </span>
              </div>
            </Link>
          </div>
          <div className="right flex flex-row absolute right-0 h-[40px] top-[0px]">
            <input
              type="text"
              className="search-input w-[300px] border-[1px] border-[#ce7205]  pl-[10px]"
              name="search-input"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="search-button rounded-r-full bg-[#ce7205] w-[80px] text-white"
              onClick={() =>
                navigate(
                  `/gongqun-center?query=${search}&page=1&newsKey=${newsKey}`
                )
              }
            >
              搜索
            </button>
          </div>
        </div>
        <div className="news-list flex flex-col  min-h-[80px] ">
          {newsList.map((item) => {
            return (
              <Link to={`/news-detail?id=${item.id}`}>
                <div
                  key={item.id}
                  className="news-item bg-white flex flex-row justify-between items-center px-[20px] py-[10px] border-b-[1px] border-[#f5f5f5] cursor-pointer"
                >
                  <div className="news-item-title">{item.title}</div>
                  <div className="news-item-time">{item.time_string}</div>
                </div>
              </Link>
            );
          })}
        </div>
        <GongGaoPage
          Search={keywords}
          limit={limit}
          current={currentPage}
          count={count}
          newsKey={newsKey}
        />
      </div>
    </div>
  );
};
export default GongQunCenter;
