import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import So from "../images/so.png";
const Search = ({ width = "1200px", inputWidth = "300px", from = "" }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/search-list?query=${search}&page=1&from=${from}`);
  };
  return (
    <div
      className={`search   w-[${width}] flex mx-auto justify-center relative h-full`}
    >
      <img
        src={So}
        className="absolute left-[10px] top-[11px] w-[26px] h-[26px] "
      />
      <input
        type="text"
        placeholder="请输入关键字"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={`w-[${inputWidth}] h-full bg-[#f1f1f1] text-black rounded-l-full pl-[78px] grow`}
      />
      <button
        className="h-full w-[95px] bg-[#ce7205] text-[#fff] rounded-r-full"
        onClick={handleSearch}
      >
        搜索
      </button>
    </div>
  );
};
export default Search;
