import { Link } from "react-router-dom";
import LogoFooter from "../images/botlogo.png";
import { useEffect, useState } from "react";
import FooterLink from "./FooterLink.component";
const Footer = () => {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await fetch("https://htadmin.biandanbao.com/api/links");
        const data = await res.json();
        console.log(data);
        setLinks(data.data);
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <footer className=" mt-[79px] pt-[10px]   h-[317px] w-full bg-[#313131] flex flex-col">
      <div className="footer-wrap w-[1200px] mx-auto  flex flex-row h-[215px] pt-[5px] pb-[35px] justify-between">
        <div className="footer-left flex flex-col font-['Roboto_Mono'] font-bold">
          <p className="text-[#fff] text-[16px] mb-[5px] self-center ">
            币安官方频道：
          </p>
          <div className="flex flex-row justify-between gap-[15px] border-t border-[#ce7205]">
            <div>
              <p className="text-[#a6a6a6] text-[14px] mt-[10px] ">
                官方频道 @BIAN 公告通知
              </p>
              <p className="text-[#a6a6a6] text-[14px] mt-[10px] ">
                币安担保 @BADB 每日公群
              </p>
              <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                币安公群 @BAGQ 静态导航
              </p>
              <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                币安供需 @BAGX 付费广告
              </p>
              <p className="text-[#a6a6a6] text-[14px] mt-[10px] ">
                纠纷仲裁 @UBER 防骗曝光
              </p>
            </div>
          </div>
        </div>
        <div className="footer-center  font-bold font-['Roboto_Mono'] flex flex-col">
          <p className="text-[#fff] text-[16px] mb-[5px] self-center">
            币安官方工作人员：
          </p>
          <div className="flex flex-row justify-between gap-[45px] border-t border-[#ce7205]">
            <div className="flex flex-col gap-[16px]">
              <div>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  公群负责人 @CPDD
                </p>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  商务负责人 @USDD
                </p>
              </div>
              <div>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  供需负责人 @ZARA
                </p>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  财务负责人 @BILL
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[16px]">
              <div>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  专群客服 @YUYU
                </p>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  专群客服 @GBPT
                </p>
              </div>
              <div>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  纠纷仲裁 @ZOOM
                </p>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  纠纷仲裁 @SCZL
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[16px]">
              <div>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  纠纷接待 @ORDI
                </p>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  纠纷接待 @SEDO
                </p>
              </div>
              <div>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  币游经理 @ZALO
                </p>
                <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
                  币游经理 @TELO
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-right flex flex-col  font-bold font-['Roboto_Mono'] ">
          <p className="text-[#fff] text-[16px] mb-[5px] self-center">
            投诉/意见/建议：
          </p>
          <div className="border-t border-[#ce7205] flex flex-col">
            <p className="text-[#a6a6a6] text-[14px] mt-[10px] self-start ">
              币安监督 @JianDu
            </p>
            <p className="text-[#a6a6a6] text-[14px] mt-[10px] self-start">
              币安监察 @JianCha
            </p>
          </div>
          <div className="flex flex-col  mt-[10px]">
            <div className="w-[97px] text-[#fff] text-[16px] mb-[5px] border-b border-[#ce7205] ">
              币安机器人：
            </div>
            <p className="text-[#a6a6a6] text-[14px] mt-[10px] self-start">
              公群管家 @BADBbot
            </p>
          </div>
        </div>
        {/* <div className="footer-right flex flex-col  font-bold font-['Roboto_Mono']">
          <p className="text-[#fff] text-[16px] mb-[25px] ">币安机器人：</p>
          <p className="text-[#a6a6a6] text-[14px] mt-[10px]">超级索引 @CJSY</p>
          <p className="text-[#a6a6a6] text-[14px] mt-[10px]">超级钱包 @PPAY</p>
          <p className="text-[#a6a6a6] text-[14px] mt-[10px]">超级监听 @TUSD</p>
          <p className="text-[#a6a6a6] text-[14px] mt-[10px]">
            公群管家 @BADBbot
          </p>
        </div> */}
      </div>
      <div className="bg-[#232323] flex flex-col pt-[15px]">
        <img
          src={LogoFooter}
          width="150px"
          height={`35px`}
          className="self-center"
        />
        <div className="self-center text-[#ffffff] my-[10px]">
          <Link to="/config?key=yszc">隐私政策</Link> ｜
          <Link to="/config?key=gszs">公司证书</Link>｜
          <Link to="/config?key=yyzz">营业执照</Link> ｜
          <Link to="/config?key=flsm">法律声明</Link>
        </div>
        <div className="self-center text-[#ce8f19] my-[10px] text-[12px]">
          郑重声明：客户应自行遵守所在国家和地区的法律法规。如因客户行为违反相关法律法规，所引发的一切责任和后果均由客户自行承担，BiAn不承担任何责任。
        </div>
      </div>
      <div className="flex flex-col items-center bg-[#313131] text-[#a1a1a1] py-[15px]">
        <p>Copyright © 2023-2024 BADB All Rights Reserved </p>
        <p>A company of BiAn Co., Ltd</p>
        {/* <div className="flex flex-row gap-[10px] text-[#ce8f19]">
          {links && links.length > 0 && (
            <>
              <span>友情链接：</span>
              <FooterLink list={links} />
            </>
          )}
        </div> */}
      </div>
    </footer>
  );
};
export default Footer;
