import { Link } from "react-router-dom";
import BgQiu from "../images/bg_qiu.png";
import BgGong from "../images/bg_gong.png";
import ItemHeader from "../Components/ItemHeader.component";
const Item = ({ item }) => {
  // 将时间戳转化为多少小时 / 天前
  const time = Date.parse(new Date()) / 1000 - item.time;

  // console.log(Date.parse(new Date()) / 1000);

  let pre;
  if (time < 3600) {
    pre = `${Math.floor(time / 60)}分钟前`;
  } else if (time < 3600 * 24) {
    pre = `${Math.floor(time / 60 / 60)}小时前`;
  } else {
    pre = `${Math.floor(time / 60 / 60 / 24)}天前`;
  }
  return (
    <Link
      className="pb-[1px] item w-[225px] mt-2 h-[247px] px-[10px] bg-[#f5f5f5] flex flex-col rounded-[20px]"
      to={`/detail?id=${item.id}`}
    >
      <ItemHeader
        // text={item.type === 1 ? "供" : item.type === 2 ? "求" : "供/求"}
        type={item.type}
      />
      <div className="item-title my-[20px] text-[14px] font-normal">
        {item.title}
      </div>
      <div className="item-content grow w-full text-[14px] overflow-hidden h-[84px]">
        {item.content}
      </div>
      <div className="item-content  py-[2px] text-gray-400 text-center">
        {pre}
      </div>
    </Link>
  );
};
export default Item;
