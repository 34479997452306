import Ads from "../Ads/Ads.component";
import IndexBgAd from "../images/hb1.png";
import Notice from "../Notice/Notice.component";
import List from "../ContentList/List.component";
import Item from "../ContentList/Item.component";
import ListHeader from "../ContentList/Header.component";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Search from "../Components/Search.component";
import HomeMiddle from "../homeMiddle/HomeMiddle.component";
import Lb from "../images/lb.png";
import Lb2 from "../images/lb2.png";
import Xft from "../images/xfj.png";
import HomeShow from "./HomeShow.component";
const Home = () => {
  const [needList, setNeedList] = useState([]);
  const [needList2, setNeedList2] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [newsKey, setNewsKey] = useState(1);
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        "https://htadmin.biandanbao.com/api/getInfos?from=1"
      );
      const data = await res.json();
      // console.log(data);
      setNeedList(
        data.data.map((item) => {
          return {
            id: item.id,
            title: item.title,
            content: item.info.slice(0, 70) + "...",
            time: item.publish_time_timestamp,
            type: item.type,
            from: item.from,
          };
        })
      );
      // setNeedList(data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `https://htadmin.biandanbao.com/api/news?type=${newsKey}&num=10`
      );
      const data = await res.json();
      // console.log(data);
      setNewsList(
        data.data.map((item) => {
          return {
            id: item.id,
            title: item.title,
            content: item.info.slice(0, 70) + "...",
            time: item.publish_time_timestamp,
            type: item.type,
            time_string: item.publish_time,
          };
        })
      );
      // setNeedList(data);
    }
    fetchData();
  }, [newsKey]);
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        "https://htadmin.biandanbao.com/api/getInfos?from=2"
      );
      const data = await res.json();
      // console.log(data);
      setNeedList2(
        data.data.map((item) => {
          return {
            id: item.id,
            title: item.title.slice(0, 10),
            content: item.info.slice(0, 70) + "...",
            time: item.publish_time_timestamp,
            type: item.type,
            from: item.from,
          };
        })
      );
      // setNeedList(data);
    }
    fetchData();
  }, []);
  return (
    <div className="main">
      <Ads src={IndexBgAd} height="529" />
      {/* <Notice></Notice>
      <Search /> */}
      <HomeMiddle />

      <List>
        <ListHeader>
          <div className="flex flex-row justify-between mb-[30px] relative	pl-[40px] pr-[30px]">
            <img
              src={Lb}
              className="absolute left-[10px] top-[5px] w-[30px] h-[26px] "
            />
            <div className=" pl-1 text-[#000000] text-[24px]">
              币安担保 最新公群
              <span className="text-[#33abe0] relative">
                <img
                  src={Xft}
                  alt=""
                  className="w-[32px] h-[32px] inline absolute top-[0px] left-[20px]"
                />
                <span className="ml-[65px]">
                  {/* <Link to="https://t.me/BIAN">@BIAN</Link>
                  {` `} */}
                  <Link to="https://t.me/BADB">@BADB</Link>
                </span>
              </span>
            </div>
            <Link
              to={`search-list?query=&page=1&from=2`}
              className="text-[#565656] text-[18px] leading-[18px]"
            >
              更多
            </Link>
          </div>
        </ListHeader>
        <div className="flex flex-row flex-wrap justify-start gap-[18px]">
          {needList2.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </div>
      </List>
      <div className="block h-[50px]"></div>
      <List>
        <ListHeader>
          <div className="flex flex-row justify-between mb-[30px] relative	pl-[40px] pr-[30px]">
            <img
              src={Lb2}
              className="absolute left-[10px] top-[5px] w-[30px] h-[26px] "
            />
            <div className=" pl-1 text-[#000000] text-[24px]">
              币安供需 付费广告
              <span className="text-[#33abe0] relative">
                <img
                  src={Xft}
                  alt=""
                  className="w-[32px] h-[32px] inline absolute top-[0px] left-[20px]"
                />
                <span className="ml-[65px]">
                  <Link to="https://t.me/BAGX">@BAGX</Link>
                  {/* {` `}
                  <Link to="https://t.me/BAGQ">@BAGQ</Link> */}
                </span>
              </span>
            </div>
            <Link
              to={`search-list?query=&page=1&from=1`}
              className="text-[#565656] text-[18px] leading-[18px]"
            >
              更多
            </Link>
          </div>
        </ListHeader>
        <div className="flex flex-row flex-wrap justify-start gap-[18px]">
          {needList.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </div>
      </List>
      <div className="mt-[30px]  bg-[#f5f5f5] w-[1200px] mx-auto p-[15px] rounded-[5px]">
        <div className="news-header border-b-[2px] border-l-main-grey flex flex-row gap-[20px] pb-[20px] pl-[10px]">
          <div
            key={1}
            className={`cursor-pointer hover:text-[#ce7205] ${
              newsKey === 1 ? "text-[#ce7205]" : ""
            }`}
            onClick={() => setNewsKey(1)}
          >
            币安新闻
          </div>
          <div
            key={2}
            className={`cursor-pointer hover:text-[#ce7205] ${
              newsKey === 2 ? "text-[#ce7205]" : ""
            }`}
            onClick={() => setNewsKey(2)}
          >
            公告信息
          </div>
          <div
            key={3}
            className={`cursor-pointer hover:text-[#ce7205] ${
              newsKey === 3 ? "text-[#ce7205]" : ""
            }`}
            onClick={() => setNewsKey(3)}
          >
            常见问题
          </div>
          <div
            key={4}
            className={`cursor-pointer hover:text-[#ce7205] ${
              newsKey === 4 ? "text-[#ce7205]" : ""
            }`}
            onClick={() => setNewsKey(4)}
          >
            骗子曝光
          </div>
          <div
            key={5}
            className={`cursor-pointer hover:text-[#ce7205] ${
              newsKey === 5 ? "text-[#ce7205]" : ""
            }`}
            onClick={() => setNewsKey(5)}
          >
            防骗教学
          </div>
        </div>
        <div className="news-content flex flex-row flex-wrap justify-between	justify-items-center ">
          {newsList.map((item, index) => (
            <Link to={`news-detail?id=${item.id}`}>
              <div
                key={index}
                cursor-pointer
                className="w-[500px] flex flex-row justify-between p-[10px] border-b-[1px] border-l-main-grey cursor-pointer "
              >
                <div className="text-[#565656] text-[14px]">{item.title}</div>
                <div className="text-[#565656] text-[14px]">
                  {item.time_string}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <HomeShow />
    </div>
  );
};

export default Home;
