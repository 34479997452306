import { useState, useEffect } from "react";
import gg from "../images/gg.png";

const Notice = ({ width = "1200px" }) => {
  const [noticeList, setNoticeList] = useState([
    { content: "notice_1", time: "2024-01-01" },
    { content: "notice_2", time: "2024-01-01" },
    { content: "notice_3", time: "2024-01-01" },
  ]);
  /*循环展示 noticeList 的消息 */
  useEffect(() => {
    const interval = setInterval(() => {
      setNoticeList((prev) => {
        const next = [...prev];
        next.push(next.shift());
        return next;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div
      className={`flex justify-between items-center bg-[#f1f1f1] h-full w-[${width}]  rounded-full relative pl-[50px] pr-[70px]`}
    >
      <img
        src={gg}
        className="absolute left-[10px] top-[11px] w-[26px] h-[26px] "
      />
      <div className="flex items-center pl-4">
        <span className="text-gray-400 text-sm pl-2 mr-[30px]">网站公告:</span>
        <span className="text-black text-sm pl-2">{noticeList[0].content}</span>
      </div>
      <div className="flex items-center  ml-[30px]">
        <span className="text-gray-400 text-sm">{noticeList[0].time}</span>
      </div>
    </div>
  );
};

export default Notice;
