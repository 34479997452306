import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
const page_limit = 4;

const Page = ({ Search, limit, current, count, from }) => {
  const start =
    +current <= 1
      ? 0
      : +current <= Math.ceil(count / limit) - page_limit + 1
      ? current - 2
      : Math.ceil(count / limit) - page_limit + 1;
  const end =
    Math.ceil(count / limit) > page_limit
      ? parseInt(start) + page_limit - 1
      : Math.ceil(count / limit) - 1;
  const pageRange1 = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );
  const pageRange = pageRange1.filter((i) => i < Math.ceil(count / limit));
  const navigate = useNavigate();
  // console.log(current, Math.ceil(count / limit), pageRange);
  const handlePageChange = (event) => {
    const selectedPage = parseInt(event.target.value);
    navigate(`/search-list?query=${Search}&page=${selectedPage}&from=${from}`);
  };
  return (
    <div className="flex justify-center items-center gap-[10px] my-[20px]">
      <ul className="flex text-center gap-[10px]">
        <li className="w-[80px] h-[30px]  bg-slate-50 mr-[10px]  border border-[#cccc]	">
          <Link
            to={`/search-list?query=${Search}&page=${current - 1}&from=${from}`}
            className="text-[#999999] leading-none	text-center	"
          >
            上一页
          </Link>
        </li>

        <li className="w-[80px] h-[30px]  bg-slate-50 ml-[10px] border border-[#cccc] ">
          <Link
            to={`/search-list?query=${Search}&page=1&from=${from}`}
            className="text-[#999999] leading-none	text-center	"
          >
            首页
          </Link>
        </li>

        {
          // const start=current;
          // Array.from(
          //   pageRange,
          //   // [0, 1, 2, 3, 4],
          //   (i, _) => (
          //     <li
          //       key={i + 1}
          //       className={`h-[30px] w-[30px] border border-[#cccc]
          //     ${parseInt(current) === i + 1 ? "bg-[#ff0000]" : "bg-slate-50"}
          //    `}
          //     >
          //       <Link
          //         to={`/search-list?query=${Search}&page=${i + 1}`}
          //         className={`
          //     text-[#999999] leading-none	text-center`}
          //       >
          //         {i + 1}
          //       </Link>
          //     </li>
          //   )
          // )
          // 生成一个Select 可以选择页数
          <li>
            <select
              value={current}
              onChange={handlePageChange}
              className="w-[80px] h-[30px]  bg-slate-50 ml-[10px] border border-[#cccc] text-[#999999] leading-none	text-center	"
            >
              {Array.from({ length: Math.ceil(count / limit) }, (_, i) => (
                <option
                  key={i + 1}
                  value={i + 1}
                  className="text-[#999999] leading-none	text-center	"
                >
                  页码 {i + 1}
                </option>
              ))}
            </select>
          </li>
        }

        <li className="w-[80px] h-[30px]  bg-slate-50 ml-[10px] border border-[#cccc] ">
          <Link
            to={`/search-list?query=${Search}&page=${Math.ceil(
              count / limit
            )}&from=${from}`}
            className="text-[#999999] leading-none	text-center	"
          >
            尾页
          </Link>
        </li>

        <li className="w-[80px] h-[30px]  bg-slate-50 ml-[10px] border border-[#cccc] ">
          <Link
            to={`/search-list?query=${Search}&page=${
              current >= Math.ceil(count / limit)
                ? Math.ceil(count / limit)
                : +current + 1
            }&from=${from}`}
            className="text-[#999999] leading-none	text-center	"
          >
            下一页
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Page;
